window.dataLayer = window.dataLayer || []
function gtag() { dataLayer.push(arguments) }

gtag('js', new Date())

const trackGoogleAnalytics = (event) => {
  gtag('config', 'G-CH44QGHS7C', {
    //'cookie_flags': 'max-age=7200;secure;samesite=none',
    //'anonymize_ip': true,
    'page_location': event.data.url
  })

  //gtag('event', 'your_event', { 'anonymize_ip': true });
}

document.addEventListener('turbolinks:load', trackGoogleAnalytics)